import React from 'react'

export default function useWindowScrollDistance(max?: number) {
  const [scrollDistance, setScrollDistance] = React.useState(0)

  const handleScroll = React.useCallback(() => {
    let distance = window.scrollY
    if (max && distance > max) distance = max
    if (distance < 0) distance = 0
    setScrollDistance(distance)
  }, [max])

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return scrollDistance
}
