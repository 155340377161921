import Image from 'next/image'
import Script from 'next/script'

type Props = {
  partnerId?: number | string
}

export default function LinkedinInsightTag(props: Props) {
  const { partnerId } = props

  if (!partnerId) {
    return null
  }

  return (
    <>
      <Script id='linkedin-partner-id' strategy='afterInteractive'>
        {`
          _linkedin_partner_id = ${partnerId};
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        `}
      </Script>
      <Script id='linkedin-insight-tag' strategy='afterInteractive'>
        {`
          (function(){var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})();
        `}
      </Script>
      <noscript>
        <Image
          height={1}
          width={1}
          style={{ display: 'none' }}
          alt=''
          src={`https://px.ads.linkedin.com/collect/?pid=${partnerId}&fmt=gif`}
        />
      </noscript>
    </>
  )
}
