import Script from 'next/script'

type Props = {
  adsId?: string
  analyticsMeasurementId?: string
  analyticsTrackingId?: string
}

export default function GoogleTag(props: Props) {
  const { adsId, analyticsMeasurementId, analyticsTrackingId } = props

  const id = adsId || analyticsMeasurementId || analyticsTrackingId

  if (!id) {
    return null
  }

  return (
    <>
      <Script id='gtag' strategy='afterInteractive' src={`https://www.googletagmanager.com/gtag/js?id=${id}`} async />
      <Script id='gtag-config' strategy='afterInteractive'>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${id}');
        `}
      </Script>
    </>
  )
}
