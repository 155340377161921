import Block, { useBlockScheme } from 'library/components/Block'
import React, { useEffect, useState } from 'react'
import { SchemeType, theme } from 'theme'

import Button from 'library/components/Button'
import IconButton from 'library/components/IconButton'
import Link from 'next/link'
import NavigationLink from 'library/components/NavigationLink'
import OnetraceLogo from 'library/components/OnetraceLogo'
import styled from 'styled-components'
import useWindowScrollDistance from 'helpers/useWindowScrollDistance'

type Props = {
  openMenu: () => void
}

const Header = ({ openMenu }: Props) => {
  const [headerMode, setHeaderMode] = useState<SchemeType>('light')

  const _scheme = useBlockScheme()
  const colorScheme = headerMode || _scheme

  const minScrollDistance = 8
  const isScrolling = useWindowScrollDistance(minScrollDistance) >= minScrollDistance

  let prevYPosition = 0
  let direction = 'up'

  const getTargetBlock = (entry: IntersectionObserverEntry) => {
    const blocks = Array.from(document.querySelectorAll<HTMLElement>('[data-scheme'))
    const index = blocks.findIndex((block) => block == entry.target)

    if (index >= blocks.length - 1) {
      return entry.target
    } else {
      return blocks[index + 1]
    }
  }

  const updateHeaderColor = (target: Element) => {
    const dataSchemeValue = target.getAttribute('data-scheme') as SchemeType

    setHeaderMode(dataSchemeValue)

    document.body.style.backgroundColor = theme.colors[dataSchemeValue === 'dark' ? 'navyDark' : 'white']
  }

  const determineHeaderMode = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      direction = window.pageYOffset > prevYPosition ? 'down' : 'up'

      prevYPosition = window.pageYOffset

      const target = direction === 'down' ? getTargetBlock(entry) : entry.target

      if ((direction === 'down' && !entry.isIntersecting) || (direction === 'up' && entry.isIntersecting)) {
        updateHeaderColor(target)
      }
    })
  }

  useEffect(() => {
    const header = document.querySelector<HTMLElement>('header')

    const observer = new IntersectionObserver(determineHeaderMode, {
      root: null,
      rootMargin: `${header ? header.offsetHeight * -1 : 0}px`,
      threshold: 0,
    })

    const blocks = document.querySelectorAll<HTMLDivElement>('[data-scheme]')

    blocks.forEach((block) => {
      observer.observe(block)
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!isScrolling) {
      const blocks = Array.from(document.querySelectorAll<HTMLElement>('[data-scheme]'))

      const targetBlock = blocks.find((_, index) => index === 1)

      if (targetBlock) {
        updateHeaderColor(targetBlock)
      }
    }
  }, [isScrolling, headerMode])

  return (
    <Block scheme={colorScheme}>
      <Container $scheme={colorScheme} $isScrolling={isScrolling}>
        <Content $isScrolling={isScrolling}>
          <LogoContainer tabIndex={0} $scheme={colorScheme}>
            <Link id='onetrace' href='/' passHref tabIndex={-1}>
              <OnetraceLogo name='onetrace-logo' alt='Onetrace' />
            </Link>
          </LogoContainer>

          <DesktopMenu>
            <Left>
              <NavigationLink href='/pricing'>Pricing</NavigationLink>
              <NavigationLink href='intercom'>Need Support?</NavigationLink>
              <NavigationLink href='/contact-sales'>Contact Sales</NavigationLink>
            </Left>

            <Right>
              <Button href='https://app.onetrace.com' size='small' variant='secondary'>
                Login
              </Button>
              <Button href='/book-a-demo' size='small'>
                Book a Demo
              </Button>
            </Right>
          </DesktopMenu>

          <MobileMenu>
            <IconButton type='menu' onClick={openMenu} />
          </MobileMenu>
        </Content>
      </Container>
    </Block>
  )
}

export default Header

const Container = styled.div<{ $scheme: SchemeType; $isScrolling: boolean }>`
  width: 100%;
  position: fixed;
  background: ${({ $scheme }) => ($scheme === 'light' ? theme.colors.white90 : theme.colors.navy90)};
  backdrop-filter: blur(7px);
  transition: ${({ theme }) => theme.transitions.default};
  box-shadow: ${({ $isScrolling, $scheme }) =>
    $isScrolling
      ? $scheme === 'light'
        ? '0px 1px 20px 0px rgba(0, 0, 0, 0.05)'
        : '0px 1px 20px 0px rgba(255, 255, 255, 0.10)'
      : 'none'};

  z-index: ${({ theme }) => theme.zIndices.header};
`

const Content = styled.div<{ $isScrolling: boolean }>`
  width: 100%;
  max-width: 1960px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 12px 16px;
  margin: 0 auto;
  height: ${({ $isScrolling }) => ($isScrolling ? '56px' : '64px')};
  transition: height 0.25s ease-in-out;

  @media ${({ theme }) => theme.breakpoints.md} {
    height: ${({ $isScrolling }) => ($isScrolling ? '62px' : '82px')};
    padding: 20px 32px;
  }
`

const LogoContainer = styled.div<{ $scheme: SchemeType }>`
  flex-shrink: 0;

  :focus-visible {
    outline: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
  }
`

const DesktopMenu = styled.div`
  display: none;

  @media ${({ theme }) => theme.breakpoints.md} {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
`

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const MobileMenu = styled.div`
  display: block;

  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }
`
