import React, { useState } from 'react'

import Button from 'library/components/Button'
import Flex from 'library/components/Flex'
import Modal from 'library/components/Modal/Modal'
import Text from 'library/components/Text'
import Toggle from 'library/components/Toggle'
import styled from 'styled-components'

export type CookieSettings = {
  functional: boolean
  analytics: boolean
  marketing: boolean
}

type Props = {
  cookieSettings: CookieSettings | null
  onClose: () => void
  onSave: (settings: CookieSettings) => void
}

const ManageCookiesModal = ({ cookieSettings, onClose, onSave }: Props) => {
  const [functional, setFunctional] = useState(cookieSettings?.functional ?? true)
  const [analytics, setAnalytics] = useState(cookieSettings?.analytics ?? false)
  const [marketing, setMarketing] = useState(cookieSettings?.marketing ?? false)

  const handleSave = () => {
    const settings = {
      functional,
      analytics,
      marketing,
    }

    onSave(settings)
    onClose()
  }

  return (
    <Modal title='Manage Cookies' onClose={onClose}>
      <Container>
        <Content>
          <Section>
            <Flex column>
              <Text tag='p' type='body-small-550'>
                Strictly Necessary
              </Text>
              <Text tag='h5' color='charcoal60'>
                Essential for the site to function. Always On.
              </Text>
            </Flex>

            <Toggle value={true} locked />
          </Section>

          <Section>
            <Flex column>
              <Text tag='p' type='body-small-550'>
                Functional
              </Text>
              <Text tag='h5' color='charcoal60'>
                Used to remember preference selections and provide enhanced features.
              </Text>
            </Flex>

            <Toggle value={functional} onChange={(_, value) => setFunctional(value)} />
          </Section>

          <Section>
            <Flex column>
              <Text tag='p' type='body-small-550'>
                Analytics
              </Text>
              <Text tag='h5' color='charcoal60'>
                Used to measure usage and improve your experience.
              </Text>
            </Flex>

            <Toggle value={analytics} onChange={(_, value) => setAnalytics(value)} />
          </Section>

          <Section>
            <Flex column>
              <Text tag='p' type='body-small-550'>
                Marketing
              </Text>
              <Text tag='h5' color='charcoal60'>
                Used for targeted advertising.
              </Text>
            </Flex>

            <Toggle value={marketing} onChange={(_, value) => setMarketing(value)} />
          </Section>
        </Content>

        <Button block onClick={handleSave}>
          Accept Selected
        </Button>
      </Container>
    </Modal>
  )
}

export default ManageCookiesModal

const Container = styled.div`
  width: 343px;
  height: 440px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 24px 24px 40px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 400px;
    height: 420px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 480px;
    height: 496px;
    gap: 56px;
    padding: 32px 32px 48px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.md} {
    gap: 24px;
  }
`

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    gap: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    gap: 32px;
  }
`
