import Script from 'next/script'

type Props = {
  hubspotId?: number | string
}

export default function Hubspot(props: Props) {
  const { hubspotId } = props

  if (!hubspotId) {
    return null
  }

  return (
    <>
      <Script id='hs-script-loader' strategy='afterInteractive' src={`//js-eu1.hs-scripts.com/${hubspotId}.js`} async />
    </>
  )
}
