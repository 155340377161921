import Button from 'library/components/Button'
import Flex from 'library/components/Flex'
import NextLink from 'next/link'
import Overlay from 'library/components/Overlay'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import { useGlobalContexts } from '../GlobalContextsProvider'

type Props = {
  onAccept: () => void
}

const CookieBanner = ({ onAccept }: Props) => {
  const { setShowCookieBanner, setShowManageCookiesModal } = useGlobalContexts()

  const handleManageCookies = () => {
    setShowCookieBanner(false)
    setShowManageCookiesModal(true)
  }

  return (
    <Overlay>
      <Wrapper>
        <Container>
          <NoticeText>
            <Text tag='p' type='body-550'>
              This site uses cookies
            </Text>
            <Text tag='h5'>
              We use cookies to make our site work and also for analytics and advertising purposes. You can enable or
              disable optional cookies as desired. See our{' '}
              <NextLink href='/legal/privacy-policy' target='_blank'>
                Privacy Policy
              </NextLink>{' '}
              for more details.
            </Text>
          </NoticeText>

          <Flex gap={8} column center>
            <Button block onClick={onAccept} size='small'>
              Accept & Close
            </Button>
            <Button block variant='secondary' size='small' onClick={handleManageCookies}>
              Manage Preferences
            </Button>
          </Flex>
        </Container>
      </Wrapper>
    </Overlay>
  )
}

export default CookieBanner

const Wrapper = styled.div`
  position: fixed;
  bottom: 16px;
  left: 16px;
  border-radius: 16px;
  box-shadow: 0px 25px 45px 0px ${({ theme }) => theme.colors.charcoal20};
  z-index: 999;
  background: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.breakpoints.sm} {
    bottom: 24px;
    left: 24px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    bottom: 32px;
    left: 32px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  width: 343px;
  height: 300px;
  padding: 24px 24px 40px 24px;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 400px;
    height: 326px;
    padding: 32px 32px 48px 32px;
  }
`

const NoticeText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 320px;
  }
`
