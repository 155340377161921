import Block, { useBlockScheme } from 'library/components/Block'
import React, { useEffect, useState } from 'react'
import { SchemeType, theme } from 'theme'

import Button from 'library/components/Button'
import IconButton from 'library/components/IconButton'
import Link from 'next/link'
import NavigationLink from 'library/components/NavigationLink'
import OnetraceLogo from 'library/components/OnetraceLogo'
import styled from 'styled-components'
import useWindowScrollDistance from 'helpers/useWindowScrollDistance'

type Props = {
  openMenu: () => void
}

const Header = ({ openMenu }: Props) => {
  const [headerMode, setHeaderMode] = useState<SchemeType>('dark')

  const _scheme = useBlockScheme()
  const colorScheme = headerMode || _scheme

  const minScrollDistance = 64
  const isScrolling = useWindowScrollDistance(minScrollDistance) >= minScrollDistance

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset

    if (scrollPosition > 64) {
      setHeaderMode('light')
      document.body.style.backgroundColor = theme.colors.white
    } else {
      setHeaderMode('dark')
      document.body.style.backgroundColor = theme.colors.navyDark
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Block scheme={colorScheme}>
      <Container $scheme={colorScheme} $isScrolling={isScrolling}>
        <Content $isScrolling={isScrolling}>
          <LogoContainer tabIndex={0} $scheme={colorScheme}>
            <Link id='onetrace' href='/' passHref tabIndex={-1}>
              <OnetraceLogo name='onetrace-logo' alt='Onetrace' />
            </Link>
          </LogoContainer>

          <DesktopMenu>
            <Left>
              <NavigationLink href='/pricing'>Pricing</NavigationLink>
              <NavigationLink href='intercom'>Need Support?</NavigationLink>
              <NavigationLink href='/contact-sales'>Contact Sales</NavigationLink>
            </Left>

            <Right>
              <Button href='https://app.onetrace.com' size='small' variant='secondary'>
                Login
              </Button>
              <Button href='/book-a-demo' size='small'>
                Book a Demo
              </Button>
            </Right>
          </DesktopMenu>

          <MobileMenu>
            <IconButton type='menu' onClick={openMenu} />
          </MobileMenu>
        </Content>
      </Container>
    </Block>
  )
}

export default Header

const Container = styled.div<{ $scheme: SchemeType; $isScrolling: boolean }>`
  width: 100%;
  position: fixed;
  background: ${({ $scheme }) => ($scheme === 'light' ? theme.colors.white90 : theme.colors.navy90)};
  backdrop-filter: blur(7px);
  transition: ${({ theme }) => theme.transitions.default};
  box-shadow: ${({ $isScrolling, $scheme }) =>
    $isScrolling
      ? $scheme === 'light'
        ? '0px 1px 20px 0px rgba(0, 0, 0, 0.05)'
        : '0px 1px 20px 0px rgba(255, 255, 255, 0.10)'
      : 'none'};

  z-index: ${({ theme }) => theme.zIndices.header};
`

const Content = styled.div<{ $isScrolling: boolean }>`
  width: 100%;
  max-width: 1960px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 12px 16px;
  margin: 0 auto;
  height: ${({ $isScrolling }) => ($isScrolling ? '56px' : '64px')};
  transition: height 0.25s ease-in-out;

  @media ${({ theme }) => theme.newBreakpoints.md} {
    height: ${({ $isScrolling }) => ($isScrolling ? '62px' : '82px')};
    padding: 20px 32px;
  }
`

const LogoContainer = styled.div<{ $scheme: SchemeType }>`
  flex-shrink: 0;

  :focus-visible {
    outline: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
  }
`

const DesktopMenu = styled.div`
  display: none;

  @media ${({ theme }) => theme.newBreakpoints.md} {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
`

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const MobileMenu = styled.div`
  display: block;

  @media ${({ theme }) => theme.newBreakpoints.md} {
    display: none;
  }
`
