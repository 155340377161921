import Script from 'next/script'

type Props = {
  profileId?: number | string
}

export default function WhatConverts(props: Props) {
  const { profileId } = props

  if (!profileId) {
    return null
  }

  return (
    <>
      <Script id='iconnode' strategy='afterInteractive' src={`//scripts.iconnode.com/${profileId}.js`} />
    </>
  )
}
