import Icon from 'library/components/Icon'
import { IconType } from 'library/icons'
import NextLink from 'next/link'
import React from 'react'
import { SchemeType } from 'theme'
import Text from 'library/components/Text'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'

type Link = {
  icon: IconType
  url: string
}

const LINKS: Link[] = [
  {
    icon: 'linkedin',
    url: 'https://uk.linkedin.com/company/onetraceapp',
  },
  {
    icon: 'facebook',
    url: 'https://www.facebook.com/onetraceapp/',
  },
  {
    icon: 'x',
    url: 'https://x.com/OnetraceApp',
  },
  {
    icon: 'instagram',
    url: 'https://www.instagram.com/onetrace.app/?hl=en',
  },
]

const SocialLinks = () => {
  const scheme = useBlockScheme()

  return (
    <LinkGroup>
      <Text type='h4'>Follow Us</Text>

      <Links>
        {LINKS.map((link) => (
          <NextLink key={link.icon} href={link.url} passHref tabIndex={-1} target='_blank'>
            <SocialLink $scheme={scheme} tabIndex={0}>
              <Icon name={link.icon} color={scheme === 'dark' ? 'white' : 'charcoal'} />
            </SocialLink>
          </NextLink>
        ))}
      </Links>
    </LinkGroup>
  )
}

export default SocialLinks

const LinkGroup = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: span 3;
  }
`

const Links = styled.div`
  display: flex;
  gap: 16px;
`

const SocialLink = styled.div<{ $scheme: SchemeType }>`
  svg path {
    transition: ${({ theme }) => theme.transitions.default};
  }

  :hover {
    svg path {
      fill: ${({ theme, $scheme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
    }
  }

  :focus-visible {
    outline: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
  }
`
