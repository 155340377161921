import OnetraceLogo from 'library/components/OnetraceLogo'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'

const OnetraceInfo = () => {
  return (
    <>
      <LogoContainer>
        <OnetraceLogo name='onetrace-logo-icon' alt='onetrace-logo' />
      </LogoContainer>

      <Content>
        <Text type='body-small-550'>Onetrace is a user driven software company</Text>
        <Text type='body-small-400'>
          A powerful cloud-based application that has been developed from the ground up, Onetrace supports
          subcontractors across the construction industry, by providing one system to capture, monitor, review and
          report on all work being conducted in real time.
        </Text>
      </Content>

      <Text type='body-small-400'>©Onetrace 2024. All rights reserved</Text>
    </>
  )
}

export default OnetraceInfo

const LogoContainer = styled.div``

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
