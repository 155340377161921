import React, { useMemo } from 'react'

import NavigationLink from 'library/components/NavigationLink'
import Text from 'library/components/Text'
import styled from 'styled-components'
import { useGlobalContexts } from 'library/layout/GlobalContextsProvider'

type Link = {
  title: string
  url: string
  onClick?: () => void
}

type LinkGroup = {
  title: string
  links: Link[]
}

const PageLinks = () => {
  const { setShowManageCookiesModal } = useGlobalContexts()

  const LINKS: LinkGroup[] = useMemo(
    () => [
      {
        title: 'Company',
        links: [
          {
            title: 'Contact Us',
            url: '/contact-sales',
          },
          {
            title: 'Journal',
            url: 'https://onetrace.com/journal',
          },
          {
            title: 'Careers',
            url: 'https://careers.onetrace.com',
          },
          {
            title: 'Privacy Policy',
            url: '/legal/privacy-policy',
          },
          {
            title: 'Manage Cookies',
            url: '/',
            onClick: () => setShowManageCookiesModal(true),
          },
        ],
      },
      {
        title: 'Resources',
        links: [
          {
            title: 'Pricing',
            url: '/pricing',
          },
          {
            title: 'Help Center',
            url: 'https://help.onetrace.com/en/',
          },
          {
            title: 'Onetrace Status',
            url: 'https://status.onetrace.com/',
          },
          {
            title: 'Changelog',
            url: '/changelog',
          },
        ],
      },
      {
        title: 'App',
        links: [
          {
            title: 'iOS App',
            url: 'https://apps.apple.com/gb/app/onetrace-nexus/id6449951451',
          },
          {
            title: 'Android App',
            url: 'https://play.google.com/store/apps/details?id=com.onetrace.app.android&gl=GB',
          },
        ],
      },
    ],
    [],
  )

  return (
    <>
      {LINKS.map((group) => (
        <LinkGroup key={group.title}>
          <Text type='h4'>{group.title}</Text>

          <Links>
            {group.links.map((link) => (
              <NavigationLink key={link.title} href={link.url} variant='secondary' onClick={link?.onClick}>
                {link.title}
              </NavigationLink>
            ))}
          </Links>
        </LinkGroup>
      ))}
    </>
  )
}

export default PageLinks

const LinkGroup = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    grid-column: span 3;
  }
`

const Links = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 154px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 222px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 282px;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    width: 332px;
  }
`
