import { AnimatePresence, motion } from 'framer-motion'

import Button from 'library/components/Button'
import IconButton from 'library/components/IconButton'
import NavigationLink from 'library/components/NavigationLink'
import Overlay from 'library/components/Overlay'
import React from 'react'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  show: boolean
  onClose: () => void
}

const Menu = ({ show, onClose }: Props) => {
  const breakpoint = useBreakpoint()

  const isMobile = breakpoint === 'xs'

  const mask = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
      transition: { delay: 0.1 },
    },
  }

  const menu = {
    open: { x: isMobile ? 10 : 0 }, // Add 10px for mobile to hide background on bounce
    closed: { x: isMobile ? '100%' : 432 },
  }

  return (
    <AnimatePresence>
      {show && (
        <Mask variants={mask} initial='hidden' animate='visible' exit='hidden' overflow>
          <Wrapper onClick={onClose}>
            <Container
              onClick={(e) => e.stopPropagation()}
              variants={menu}
              initial='closed'
              animate='open'
              transition={{ type: 'spring', stiffness: 370, mass: 1, damping: 30 }}
              exit='closed'
            >
              <Header>
                <IconButton type='close' onClick={onClose} />
              </Header>

              <Nav>
                <NavigationLink href='/pricing' block>
                  Pricing
                </NavigationLink>
                <NavigationLink href='intercom' block>
                  Need Support?
                </NavigationLink>
                <NavigationLink href='/contact-sales' block>
                  Contact Sales
                </NavigationLink>
              </Nav>

              <Buttons>
                <Button variant='secondary' block href='https://app.onetrace.com'>
                  Login
                </Button>
                <Button block href='/book-a-demo'>
                  Book a Demo
                </Button>
              </Buttons>
            </Container>
          </Wrapper>
        </Mask>
      )}
    </AnimatePresence>
  )
}

export default Menu

const Mask = styled(Overlay)`
  background: transparent;
  backdrop-filter: none;

  @media ${({ theme }) => theme.breakpoints.sm} {
    background: ${({ theme }) => theme.colors.mask};
    backdrop-filter: blur(12px);
  }
`

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  @media (max-width: 254px) {
    overflow: auto;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    min-height: calc(438px + 64px);
    padding: 32px;
    overflow: hidden;
  }
`

const Container = styled(motion.div)`
  width: calc(100% + 10px); // Add 10px to width as spacer
  min-width: 265px;
  height: 100%;
  min-height: 438px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px; // Add 10px to accomodate spacer
  background: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 400px;
    height: calc(100% - 64px);
    top: 32px;
    right: 32px;
    padding-right: 0;
    border-radius: 16px;
    box-shadow: 0px 25px 45px 0px ${({ theme }) => theme.colors.charcoal20};
  }
`

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 16px 0 0;

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 16px 16px 0 0;
  }
`

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 8px;
  margin: 0 32px;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 32px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    gap: 8px;
  }
`
