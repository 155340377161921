import Script from 'next/script'

type Props = {
  siteId?: number | string
}

export default function Hotjar(props: Props) {
  const { siteId } = props

  if (!siteId) {
    return null
  }

  return (
    <>
      <Script id='hotjar' strategy='afterInteractive'>
        {`
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${siteId},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </Script>
    </>
  )
}
